import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from "react-router-dom";

export default function Authuser() { 

const navigate = useNavigate();

const bUrl = "https://adminpanel.metastarworld.in/";
const murl = "https://metastarworld.in/";

const getToken = () => {
    const tokenString = localStorage.getItem('mtoken');
    const userToken = JSON.parse(tokenString);
    return userToken;
}
                            
const logout = () => {
    localStorage.clear();
    navigate('/');
}

const [token,setToken] = useState();

const saveToken = (token) => {
    localStorage.setItem('mtoken', JSON.stringify(token));
    setToken(token);
    window.location.href="dashboard";
}
  
    const http = axios.create({
        baseURL: "https://adminpanel.metastarworld.in/api",
        headers: {
            "content-type":"application/json"
        }
    });

    return {
        setToken:saveToken,
        token,
        getToken,
        http,
        bUrl,
        murl,
        logout
    }

}
