import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Authuser from '../api/Authuser';

export default function Protected(props) {

const {Component} = props
const navigate = useNavigate();
const {getToken} = Authuser();
useEffect(()=>{
    if(!getToken()){
      navigate('/'); 
    }
},[]);

  return (
    <>
        <Component />
    </>
  )
}
