import React, { useState }  from 'react';
import Authuser from "../api/Authuser";

export default function Login() {

const {http,setToken} = Authuser();
const [username,setUsername] = useState();
const [errorusername,setErrorusername] = useState();
const [loader,setLoader] = useState('off');
const [errormsg,setErrormsg] = useState(null);

const submitForm = () => {
    setLoader('on');
    http.post('/login',{username:username}).then(res=>{
      if(res.data.status==422){
        setErrorusername(res.data.error.username);
      }else if(res.data.status==300){
        setErrormsg(res.data.errormsg);
        setErrorusername('');
      }else{
        setToken(res.data.user.id); 
      }
      setLoader('off');
    });
  }


  return (
    <div>


<form id="reg-form" autoComplete="off">
<div className="form-group">
    <div className="input-group">
        <span className="input-group-addon"><i className="fa fa-user"></i></span>
        <input className="form-control" onChange={e=>setUsername(e.target.value)} placeholder="Enter Your Userid" type="text" />
        <span style={{color:'red'}}>{errorusername}</span>
    </div>
</div>

<button type="button" onClick={submitForm} className="site-button-secondry text-uppercase btn-block m-b10">
{loader==='off' &&
<span>Submit</span>
}
{loader==='on' &&
<span style={{color:'green'}}>Please Wait...</span>
}
</button>

{errormsg!=null &&
<div className='alert alert-danger'>{errormsg}</div>
}

</form>


    </div>
  )
}
