import React from 'react';
import Guest from './layouts/Guest';
import Authuser from './api/Authuser';
import Auth from './layouts/Auth';

function App() {

  const {getToken} = Authuser();
  if(!getToken()){
    return <Guest /> 
  }

  return (
    <>
      <Auth />
    </>
  );

}

export default App;
