import React, { useEffect,useState,useRef }  from 'react';
import Authuser from "../api/Authuser";

export default function Withdrawal() {

  const {http,setToken} = Authuser();
  const token = localStorage.getItem('mtoken');
  const [profiledetails,setProfiledetails] = useState([]);
  const [address,setAddress] = useState(null);
  const [erroraddress,setErroraddress] = useState(null);
  const [loader,setLoader] = useState('off');
  const [successmsg,setSuccessmsg] = useState(null);
  const [errormsg,setErrormsg] = useState(null);
  const ref = useRef(null);

  useEffect(()=>{
    getprofiledetails();
},[]); 

const getprofiledetails = () =>{
  http.get('/profile-details/'+token).then(res=>{
    setProfiledetails(res.data);
    setAddress(profiledetails.wallet_address);
  });
};

const submitForm = () => {
  setAddress(ref.current.value);
  var cad = ref.current.value;
  var am = parseFloat((profiledetails.total_auto_income)-profiledetails.total_auto_withdrawal);
  if(am>0){
  setLoader('on');
  http.post('/withdrawal',{address:cad,token:token,amount:am}).then(res=>{
    if(res.data.status==422){
      setErroraddress(res.data.error.address);
    }else if(res.data.status==300){
      setErrormsg(res.data.errormsg);
    }else{
      setSuccessmsg(res.data.successmsg);
    }
    setLoader('off');
  });
  }else{
    setErrormsg('Insufficient Balance');
  }
}

  return (
    <div>


<div className="container">
<div className="row">
<div className="col-md-12 mb-2" style={{borderBottom:'5px solid blue'}}>
<div className="card">
    <div className="card-header text-center">
    {/* <h4 style={{fontWeight:'bolder'}} className="text-primary">Total Income: ${parseFloat((profiledetails.total_level_income+profiledetails.total_autopoll_income))}</h4> */}
    </div>
    <div className="card-body text-center">
    {/* <h5>Total Withdrawal: ${parseFloat((profiledetails.total_withdrawal))}</h5> */} 
    {/* <h5>Withdrawal Balance: ${parseFloat((profiledetails.total_level_income+profiledetails.total_autopoll_income)-profiledetails.total_withdrawal)}</h5> */}
    <h5>Withdrawal Balance: {parseFloat((profiledetails.total_auto_income)-profiledetails.total_auto_withdrawal)} BNB</h5>
    </div> 
</div>
</div>
</div>

<div className="row mb-3">

<div className="col-md-12">
<div className="card">
<div className="card-header text-center"><h4 className="text-success">Withdrawal</h4></div>
<div className="card-body mt-2" style={{padding:'10px'}}>
    <input type="text" className="form-control mb-1" ref={ref} defaultValue={profiledetails.wallet_address} readonly="readonly"/>
    <span style={{color:'red'}}>{erroraddress}</span>
    <button type="button" onClick={submitForm} className="btn btn-primary btn-sm btn-block">
    {loader==='off' &&
    <span>Submit</span>
    }
    {loader==='on' &&
    <span style={{color:'green'}}>Please Wait...</span>
    }
    </button>
</div>
</div>

{successmsg!=null &&
<div className='alert alert-success'>{successmsg}</div>
}
{errormsg!=null &&
<div className='alert alert-danger'>{errormsg}</div>
}

</div>
</div>
</div>



    </div>
  )
}
