import React, { useEffect, useState } from 'react';
import { Routes,Route,useNavigate,Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Authuser from "../api/Authuser";
import Protected from '../components/Protected';
import Login from '../pages/Login';
import Dashboard from '../pages/Dashboard';
import Package from '../pages/Package';
import Refferal from '../pages/Refferal';
import Reffteam from '../pages/Refftem';
import Totalteam from '../pages/Totalteam';
import Levelincome from '../pages/Levelincome';
import Autopoolincome from '../pages/Autopoolincome';
import Updatewallet from '../pages/Updatewallet';
import Withdrawal from '../pages/Withdrawal';
import { useAccount } from "wagmi";


export default function Auth() { 

  const navigate = useNavigate();
  const {http,getToken,logout} = Authuser();
  const token = localStorage.getItem('mtoken');
  const [profiledetails,setProfiledetails] = useState([]);
  const [loader,setLoader] = useState('off');
  const [address, setAddress] = useState(null);
  const [connector, setConnector] = useState({});

  useEffect(()=>{
    getprofiledetails();
},[]); 

const getprofiledetails = () =>{
  setLoader('on');
  http.get('/profile-details/'+token).then(res=>{
      setProfiledetails(res.data);
      setLoader('off');
  });
};

const logoutUser = () =>{
  if(getToken()){
    localStorage.clear();
    navigate('/');
  }
} 

const data1 = useAccount({
onConnect({ address, connector, isReconnected }) {
    setAddress(address);
    setConnector(connector);
    http.post('/update-address',{address:address,token:token}).then(res=>{
    });
},
onDisconnect() {
    
},
});


  return (
    <>


<div className="page-wraper">
<header className="site-header header-style-6">

<div className="top-bar bg-primary">
<div className="container">
<div className="row">
<div className="clearfix">
<div className="wt-topbar-left">
    <ul className="list-unstyled e-p-bx pull-left">
        <li><i className="fa fa-envelope"></i>info@metastarworld.in</li>
        <li><i className="fa fa-phone"></i>+41 32 377 77 77</li>
    </ul>
</div>

{ token==='' &&
<div className="wt-topbar-right">
<ul className="list-unstyled e-p-bx pull-right">
<li><a href="#" data-toggle="modal" data-target="#Register-form"><i className="fa fa-eye"></i>View Id</a></li>
</ul>
</div>
}
<div className="wt-topbar-right">
<ul className="list-unstyled e-p-bx pull-right">
<li>
    { token!=='' &&
    <span>{profiledetails.name} [ {profiledetails.username} ]</span>
    }
</li>
<li>
{ profiledetails.astatus === 1 &&
    <span style={{color:'green', fontWeight:'bolder'}}>&nbsp;Active</span>
}
  </li>
</ul>
</div>

</div>
</div>
</div>
</div>

<div className="main-bar header-middle bg-white">
<div className="container">
<div className="logo-header">
<a href="/">
<img src="/assets/images/MS LOGO.png" />
</a>
</div>
<div className="header-info">
<ul>
<li>
    <div>
        <div className="icon-sm">
            <span className="icon-cell  text-primary"><i className="iconmoon-travel"></i></span>
        </div>
        <div className="icon-content">
            <strong>Our Location </strong>
            <span>Stauffacherstrasse 65/59g . CH-3003,Bern</span>
        </div>
    </div>
</li>
<li>
    <div>
        <div className="icon-sm">
            <span className="icon-cell  text-primary"><i
                    className="iconmoon-smartphone-1"></i></span>
        </div>
        <div className="icon-content">
            <strong>Phone Number</strong>
            <span>+41 32 377 77 77</span>
        </div>
    </div>
</li>

</ul>
</div>
</div>
</div>


<div className="sticky-header main-bar-wraper">
<div className="main-bar header-botton nav-bg-secondry">
<div className="container">

<button data-target=".header-nav" data-toggle="collapse" type="button"
className="navbar-toggle collapsed">
<span className="sr-only">Toggle navigation</span>
<span className="icon-bar"></span>
<span className="icon-bar"></span>
<span className="icon-bar"></span>
</button>

<div className='extra-nav'>
<w3m-button />
</div>

<div className="header-nav navbar-collapse collapse ">

<ul className="nav navbar-nav">
    <li>
        <Link reloadDocument to="/dashboard">Home</Link>
    </li>
    <li>
    <Link reloadDocument to="/refferal-link">Refferal Link</Link>
    </li>
    <li><Link reloadDocument to="/update-package">Update Package</Link></li>
    <li>
    <a href="javascript:;">Team </a> 
    <ul className="sub-menu">
        <li>
        <Link reloadDocument to="/reff-team">Refferal Team</Link>
        </li>
        <li>
        <Link reloadDocument to="/total-team">Total Team</Link>
        </li>
    </ul>
    </li>

    <li>
    <a href="javascript:;">Incomes </a>
    <ul className="sub-menu">
        <li>
        <Link reloadDocument to="/level-income">Level Incomes</Link>
        </li>
        <li>
        <Link reloadDocument to="/autopool-income">Autopool Incomes</Link>
        </li>
    </ul>
    </li>
    <li>
    <a href="javascript:;">Wallet</a>
    <ul className="sub-menu">
        <li>
        <Link reloadDocument to="/update-wallet">Total Income</Link>
        </li>
        <li>
        <Link reloadDocument to="/withdrawal">Withdrawal</Link>
        </li>
    </ul>
    </li>
    <li>
    <a onClick={logoutUser} >Logout</a>
    </li>
  </ul>


</div>
</div>
</div>
</div>
</header>


<Routes>
    <Route path='/dashboard' element={<Protected Component={Dashboard} />} />
    <Route path='/update-package' element={<Protected Component={Package} />} />
    <Route path='/refferal-link' element={<Protected Component={Refferal} />} />
    <Route path='/reff-team' element={<Protected Component={Reffteam} />} />
    <Route path='/total-team' element={<Protected Component={Totalteam} />} />
    <Route path='/level-income' element={<Protected Component={Levelincome} />} />
    <Route path='/autopool-income' element={<Protected Component={Autopoolincome} />} />
    <Route path='/update-wallet' element={<Protected Component={Updatewallet} />} />
    <Route path='/withdrawal' element={<Protected Component={Withdrawal} />} />
</Routes>



<footer className="site-footer footer-dark bg-no-repeat bg-full-height bg-center "
    style={{backgroundImage:"url('/assets/images/background/footer-bg.jpg')"}}>

    <div className="footer-top overlay-wraper">
        <div className="overlay-main bg-black opacity-05"></div>
        <div className="container">
            <div className="row">
                <div className="col-md-3 col-sm-6">
                    <div className="widget widget_about">
                        <h4 className=" text-white">About Company</h4>
                        <div className="logo-footer clearfix p-b15">
                            <a href="#"><img src="/assets/images/MS LOGO.png"
                                    style={{height: '75px'}} alt="" /></a>
                        </div>
                        <p>
If you know what exactly you are looking for, then we have the right thing for you! Now save your money by spending on exactly what you are looking to learn. We make it really simple for you to choose what you need and customise your learning process for an efficient and effective learning process! Save time and money while developing solid skills and knowledge!!
                        </p>
                    </div>
                </div>


                <div className="col-md-3 col-sm-6">
                    <div className="widget widget_services">
                        <h4 className=" text-white">Useful links</h4>
                        <ul>
                            <li><a href="#">About</a></li>
                            <li><a href="#">FAQ</a></li>
                            <li><a href="#">Our Team</a></li>
                            <li><a href="#">Services</a></li>
                            <li><a href="#">Contact Us</a></li>
                        </ul>
                    </div>
                </div>

                <div className="col-md-3 col-sm-6">
                    <div className="widget widget_newsletter">
                        <h4 className=" text-white">Newsletter</h4>
                        <div className="newsletter-bx">
                            <form role="search" method="post">
                                <div className="input-group">
                                    <input name="news-letter" className="form-control" placeholder="ENTER YOUR EMAIL"
                                        type="text"/>
                                    <span className="input-group-btn">
                                        <button type="submit" className="site-button"><i
                                                className="fa fa-paper-plane-o"></i></button>
                                    </span>
                                </div>
                            </form>
                        </div>
                    </div>

                    <div className="widget widget_social_inks">
                        <h4 className=" text-white">Social Links</h4>
                        <ul className="social-icons social-square social-darkest">
                            <li><a href="javascript:void(0);" className="fa fa-facebook"></a></li>
                            <li><a href="javascript:void(0);" className="fa fa-twitter"></a></li>
                            <li><a href="javascript:void(0);" className="fa fa-linkedin"></a></li>
                            <li><a href="javascript:void(0);" className="fa fa-rss"></a></li>
                            <li><a href="javascript:void(0);" className="fa fa-youtube"></a></li>
                            <li><a href="javascript:void(0);" className="fa fa-instagram"></a></li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className="row">

                <div className="col-md-4 col-sm-6  p-tb20">
                    <div className="wt-icon-box-wraper left  bdr-1 bdr-gray-dark p-tb15 p-lr10 clearfix">
                        <div className="icon-md text-primary">
                            <span className="iconmoon-travel"></span>
                        </div>
                        <div className="icon-content text-white">
                            <h5 className="wt-tilte text-uppercase m-b0">Address</h5>
                            <p>Stauffacherstrasse 65/59g . CH-3003,Bern</p>
                        </div>
                    </div>
                </div>
                <div className="col-md-4 col-sm-6  p-tb20 ">
                    <div className="wt-icon-box-wraper left  bdr-1 bdr-gray-dark p-tb15 p-lr10 clearfix ">
                        <div className="icon-md text-primary">
                            <span className="iconmoon-smartphone-1"></span>
                        </div>
                        <div className="icon-content text-white">
                            <h5 className="wt-tilte text-uppercase m-b0">Phone</h5>
                            <p className="m-b0">+41 32 377 77 77</p>

                        </div>
                    </div>
                </div>

                <div className="col-md-4 col-sm-6 p-tb20">
                    <div className="wt-icon-box-wraper left  bdr-1 bdr-gray-dark p-tb15 p-lr10 clearfix">
                        <div className="icon-md text-primary">
                            <span className="iconmoon-email"></span>
                        </div>
                        <div className="icon-content text-white">
                            <h5 className="wt-tilte text-uppercase m-b0">Email</h5>
                            <p className="m-b0">info@metastarworld.in</p>

                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>

    <div className="footer-bottom  overlay-wraper">
        <div className="overlay-main"></div>
        <div className="constrot-strip"></div>
        <div className="container p-t30">
            <div className="row">
                <div className="wt-footer-bot-left">
                    <span className="copyrights-text">©2023 Meta Star World. All Rights Reserved.</span>
                </div>
                <div className="wt-footer-bot-right">
                    <ul className="copyrights-nav pull-right">
                        <li><a href="javascript:void(0);">Terms & Condition</a></li>
                        <li><a href="javascript:void(0);">Privacy Policy</a></li>
                        <li><a href="#">Contact Us</a></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</footer>


<button className="scroltop"><span className=" iconmoon-house relative" id="btn-vibrate"></span>Top</button>


<div id="Register-form" className="modal fade " role="dialog">
<div className="modal-dialog modal-sm">
<div className="modal-content">
<div className="modal-header bg-primary">
<button type="button" className="close" data-dismiss="modal">&times;</button>
<h4 className="modal-title text-white">View Id</h4>
</div>
<div className="modal-body p-a30">
<Login />
</div>
<div className="modal-footer text-center">
<div className="text-center"><img src="/assets/images/MS LOGO.png" style={{height: '75px'}} alt="" /></div>
</div>
</div>
</div>
</div>


</div>


<Helmet>
    <script src="/assets/js/jquery-1.12.4.min.js"></script>
    <script src="/assets/js/bootstrap.min.js"></script>
    <script src="/assets/js/bootstrap-select.min.js"></script>
    <script src="/assets/js/jquery.bootstrap-touchspin.min.js"></script>
    <script   src="/assets/js/magnific-popup.min.js"></script>
    <script   src="/assets/js/waypoints.min.js"></script>
    <script   src="/assets/js/counterup.min.js"></script>
    <script   src="/assets/js/waypoints-sticky.min.js"></script>
    <script  src="/assets/js/isotope.pkgd.min.js"></script>
    <script   src="/assets/js/owl.carousel.min.js"></script>
    <script   src="/assets/js/stellar.min.js"></script> 
    <script   src="/assets/js/scrolla.min.js"></script>
    <script   src="/assets/js/custom.js"></script>
    <script   src="/assets/js/shortcode.js"></script>
    <script   src="/assets/js/switcher.js"></script>
    <script  src="/assets/js/jquery.bgscroll.js"></script>
    <script  src="/assets/js/tickerNews.min.js"></script>
    <script  src="/assets/plugins/revolution/revolution/js/jquery.themepunch.tools.min.js"></script>
    <script  src="/assets/plugins/revolution/revolution/js/jquery.themepunch.revolution.min.js"></script>
    <script  src="/assets/plugins/revolution/revolution/js/extensions/revolution-plugin.js"></script>
    <script   src="/assets/js/rev-script-2.js"></script>
</Helmet>

    </>
  )
}
