import React, { useEffect,useState }  from 'react';
import Authuser from "../api/Authuser";

export default function Updatewallet() {

  const {http,setToken} = Authuser();
  const token = localStorage.getItem('mtoken');
  const [address,setAddress] = useState(null);
  const [erroraddress,setErroraddress] = useState(null);
  const [loader,setLoader] = useState('off');
  const [successmsg,setSuccessmsg] = useState(null);
  const [profiledetails,setProfiledetails] = useState([]);

  useEffect(()=>{
    getprofiledetails();
},[]); 

const getprofiledetails = () =>{
  http.get('/profile-details/'+token).then(res=>{
    setProfiledetails(res.data);
  });
};

  const submitForm = () => {
    setLoader('on');
    http.post('/update-address',{address:address,token:token}).then(res=>{
      if(res.data.status==422){
        setErroraddress(res.data.error.address);
      }else{
        setSuccessmsg(res.data.successmsg);
      }
    });
  }


  return (
    <div>


<div className="container">
<div className="row mb-3">
<div className="col-md-12">

{/* <div className="card">
<div className="card-header text-center"><h4 className="text-danger">Wallet Address</h4></div>
<div className="card-body mt-2" style={{padding:'10px'}}>

<input type="text" onChange={e=>setAddress(e.target.value)} defaultValue={profiledetails.wallet_address} className="form-control" readonly={profiledetails.wallet_address!=='' && 'readonly' } />
<span style={{color:'red'}}>{erroraddress}</span>

{profiledetails.wallet_address==='' &&
<button type="button" onClick={submitForm} className="btn btn-primary btn-sm btn-block">Save</button>
}
{profiledetails.wallet_address!=='' &&
<button type="button" className="btn btn-success btn-sm btn-block">Address Updated</button>
} 

{successmsg!=null &&
<div className='alert alert-success'>{successmsg}</div>
}
</div>
</div> */}

<div className="card">
    <div className="card-header text-center">
    <h4 style={{fontWeight:'bolder'}} className="text-primary">Total Income: {parseFloat((profiledetails.total_level_income+profiledetails.total_autopoll_income))} BNB</h4>
    </div>
    <div className="card-body text-center">
    </div> 
</div>

</div>
</div>
</div>


    </div>
  )
}
