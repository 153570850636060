import React, { useEffect, useState } from 'react';
import Authuser from "../api/Authuser";

export default function Autopoolincome() {


  const {http} = Authuser();
  const token = localStorage.getItem('mtoken');
  const [profiledetails,setProfiledetails] = useState([]);
  const [autopoolincome,setAutopoolincome] = useState([]);

  useEffect(()=>{
    getprofiledetails();
    getautopoolincome();
},[]); 

const getprofiledetails = () =>{
  http.get('/profile-details/'+token).then(res=>{
    setProfiledetails(res.data);
  });
};

const getautopoolincome = () =>{
  http.get('/autopoll-income/'+token).then(res=>{
    setAutopoolincome(res.data.income);
  });
}


  return (
    <div>


<div className="container">
<div className="row mb-3">
<div className="col-md-12">

<h3 className="text-center"> AutoPool Incomes ({profiledetails.total_autopoll_income+0} BNB)</h3>
<div className="container">
<table className="table table-striped">
<thead>
<tr>
        <th scope="col">Sl No.</th>
        <th scope="col">Slot</th>
        <th scope="col">Return Amount</th> 

</tr>
</thead>
<tbody>
      {autopoolincome.map((item,index)=>( 
        <tr key={index}>
            <th scope="row">{index+1}</th>
            <td>{item.slot}</td>
            <td>{parseFloat((item.amount))}</td>
        </tr>
      ))}
</tbody>
</table>
</div>
</div>
</div>
</div>


    </div>
  )
}
