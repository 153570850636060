import React, { useEffect, useState } from 'react';
import Authuser from "../api/Authuser";
import Userdetails from '../components/Userdetails';

export default function Refftem() {

  const {http} = Authuser();
  const token = localStorage.getItem('mtoken');
  const [profiledetails,setProfiledetails] = useState([]);
  const [reffteam,setReffteam] = useState([]);

  useEffect(()=>{
    getprofiledetails();
    getreffteam();
},[]); 

const getprofiledetails = () =>{
  http.get('/profile-details/'+token).then(res=>{
    setProfiledetails(res.data);
  });
};

const getreffteam = () =>{
  http.get('/reff-team/'+token).then(res=>{
    setReffteam(res.data.team);
  });
}


  return (
    <div>


<div className="container">
<div className="row mb-3">
<div className="col-md-12">

<h3 className="text-center"> Refferal Team ({profiledetails.directgroup})</h3>
<div className="container">
<table className="table table-striped">
<thead>
<tr>
    <th scope="col">Sl No.</th>
    <th scope="col">Date</th>
    <th scope="col">User Details</th>
    <th scope="col">Level</th>

</tr>
</thead>
<tbody>
      {reffteam.map((item,index)=>( 
        <tr key={index}>
            <th scope="row">{index+1}</th>
            <td>{item.created_at}</td>
            <td><Userdetails userid={item.member_id} /></td> 
            <td>{item.gen_type}</td>
        </tr>
      ))}
</tbody>
</table>
</div>
</div>
</div>
</div>



    </div>
  )
}
