
import  { ReactNode } from 'react';
import { createWeb3Modal } from '@web3modal/wagmi/react';
import { walletConnectProvider, EIP6963Connector } from '@web3modal/wagmi';
import { QueryClient, QueryClientProvider } from 'react-query';
import { WagmiConfig, configureChains, createConfig } from 'wagmi';
import { publicProvider } from 'wagmi/providers/public';
import { bsc } from 'wagmi/chains';
import { CoinbaseWalletConnector } from 'wagmi/connectors/coinbaseWallet';
import { InjectedConnector } from 'wagmi/connectors/injected';
import { WalletConnectConnector } from 'wagmi/connectors/walletConnect';

const queryClient = new QueryClient();

// 1. Get projectId
const projectId = 'd488a1b0f54824808168378d58fced1d';

// 2. Create wagmiConfig
const { chains, publicClient } = configureChains(
  [bsc],
  [walletConnectProvider({ projectId }), publicProvider()]
);

const metadata = {
  name: 'Web3Modal',
  description: 'Web3Modal Example',
  url: 'https://web3modal.com',
  icons: ['https://avatars.githubusercontent.com/u/37784886']
};

const wagmiConfig = createConfig({
  autoConnect: true,
  connectors: [
    new WalletConnectConnector({ chains, options: { projectId, showQrModal: false, metadata } }),
    new EIP6963Connector({ chains }),
    new InjectedConnector({ chains, options: { shimDisconnect: true } }),
    new CoinbaseWalletConnector({ chains, options: { appName: metadata.name } })
  ],
  publicClient
});

// 3. Create modal 
createWeb3Modal({ wagmiConfig, projectId, chains })
export default function Webthreemodal({children}) {
  return ( 
    <>
    <QueryClientProvider client={queryClient} contextSharing={true}>
    <WagmiConfig config={wagmiConfig}>
    {children}
   
    </WagmiConfig>
    </QueryClientProvider>
    </>
  )
}