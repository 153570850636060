import React, { useEffect, useState } from 'react';
import Authuser from "../api/Authuser";

export default function Userstatus(props) {

    const {http} = Authuser();
    const token = localStorage.getItem('mtoken');
    const [slotdetails,setSlotdetails] = useState(null);

    useEffect(()=>{
        getslotdetails();
    },[]); 
    
    const getslotdetails = () =>{
      http.get('/user-slot-details/'+props.slotn+'/'+token).then(res=>{
        setSlotdetails(res.data.active);
        console.log(res.data.active);
      });
    };

  return (
    <div>

<span>
{ slotdetails == 1 &&
<span className="text-white badge bg-sucess mb-3" style={{background:'green'}}>Status : Active</span>
}
{ slotdetails != 1 &&
<span className="text-white badge bg-sucess mb-3" style={{background:'red'}}>Status : Inactive</span>
}
</span>


    </div>
  )
}
