import React, { useEffect, useState } from 'react';
import Authuser from "../api/Authuser";
import {formatUnits} from 'viem';
import { parseEther } from "viem";
import { useSendTransaction } from "wagmi";
import Userstatus from "../components/Userstatus";

export default function Package() {

  const {http} = Authuser();
  const token = localStorage.getItem('mtoken');
  const [allslots,setAllslots] = useState([]);
  const [slot,setSlot] = useState(null);
  const [errorslot,setErrorslot] = useState(null);
  const [slotprice,setSlotprice] = useState(0);
  const [loader,setLoader] = useState('off');
  const [successmsg,setSuccessmsg] = useState(null);
  const [errormsg,setErrormsg] = useState(null);
  const [profiledetails,setProfiledetails] = useState([]);

  const [txhash, setTxhash] = useState(null);

  const { data, isLoading, isSuccess, sendTransaction } = useSendTransaction({
    to: "0xbAaDCD7Eb1795C9Fd31AF9f1ea7E0a6fA0A3Fcd6",
    value: parseEther(`${slotprice}`),
    onSuccess(data) {
      setTxhash(data?.hash);
      var hast = data.hash;
      http.post('/deposit',{slot:slot,slotprice:slotprice,token:token,txhash:hast}).then(res=>{
        window.location.reload(); 
      });
    },
  })


  useEffect(()=>{
    getprofiledetails();
    getallslots();
    setTxhash(data?.hash);
},[data, isLoading, isSuccess]); 

const getallslots = () =>{
  http.get('/all-slots').then(res=>{
    setAllslots(res.data.slots);
  });
};

const slotpricefind = (e) =>{
  setSlot(e.target.value);
  var slotid = e.target.value;
  http.get('/slot-details/'+slotid).then(res=>{
    setSlotprice(res.data.slotprice);
  });
}

const getprofiledetails = () =>{
  http.get('/profile-details/'+token).then(res=>{
    setProfiledetails(res.data);
  });
};

const slotpop = (e) =>{
  setSlot(e.target.value);
  var slotid = e.target.value;
  http.get('/slot-details/'+slotid).then(res=>{
    setSlotprice(res.data.slotprice);
  });
}




return (
    <div>


<div className="section-full bg-gray">
<div className="container">

<h3 className="text-primary text-center">Update Package</h3>

<div className="section-content no-col-gap">
<div className="row mb-2">

<div className="col-md-4 col-sm-4 step-number-block" >
<div className="wt-icon-box-wraper  p-a30 center bg-white m-a5" style={{background: "linear-gradient(to bottom, #ff6600 0%, #000000 58%)"}}>
<div className="icon-lg text-primary m-b20">
<a href="#" className="icon-cell"></a>
</div>
<div className="icon-content">
<div className="step-number"></div>
<h4 className="wt-tilte text-white text-uppercase font-weight-500">Slot 1</h4>
<p className="text-white"><b>0.05 BNB</b></p>

<Userstatus slotn={1} />

{ profiledetails.activation_slot !== 1 ?
<button type="button" data-toggle="modal" data-target="#slot-form" value="1" onClick={slotpop}  className="site-button-secondry text-uppercase btn-block m-b10">Upgrade Slot</button>
:
<button type="button" className="site-button-secondry text-uppercase btn-block m-b10" >Upgrade Slot</button>
}


</div>
</div>
</div>

<div className="col-md-4 col-sm-4 step-number-block">
<div className="wt-icon-box-wraper  p-a30 center bg-white m-a5 " style={{background: "linear-gradient(to bottom, #33ccff 0%, #000000 58%)"}}>
<div className="icon-lg m-b20 text-primary">
<a href="#" className="icon-cell"></a>
</div>
<div className="icon-content">
<div className="step-number active"></div>
<h4 className="wt-tilte text-white text-uppercase font-weight-500">Slot 2</h4>
<p className="text-white"><b>0.10 BNB</b></p>

<Userstatus slotn={2} />

{ profiledetails.activation_slot !== 2 ?
<button type="button" data-toggle="modal" data-target="#slot-form" value="2" onClick={slotpop}  className="site-button-secondry text-uppercase btn-block m-b10">Upgrade Slot</button>
:
<button type="button" className="site-button-secondry text-uppercase btn-block m-b10" >Upgrade Slot</button>
}

</div>
</div>
</div>

<div className="col-md-4 col-sm-4 step-number-block">
<div className="wt-icon-box-wraper  p-a30 center bg-white m-a5" style={{background: "linear-gradient(to bottom, #99ff99 0%, #000000 58%)"}}>
<div className="icon-lg text-primary m-b20">
<a href="#" className="icon-cell"></a>
</div>
<div className="icon-content">
<div className="step-number"></div>
<h4 className="wt-tilte text-white text-uppercase font-weight-500">Slot 3</h4>
<p className="text-white"><b>0.20 BNB</b></p>

<Userstatus slotn={3} />

{ profiledetails.activation_slot !== 3 ?
<button type="button" data-toggle="modal" data-target="#slot-form" value="3" onClick={slotpop}  className="site-button-secondry text-uppercase btn-block m-b10">Upgrade Slot</button>
:
<button type="button" className="site-button-secondry text-uppercase btn-block m-b10" >Upgrade Slot</button>
}

</div>
</div>
</div>
</div>


<div className="row  mb-2">
<div className="col-md-4 col-sm-4 step-number-block">
<div className="wt-icon-box-wraper  p-a30 center bg-white m-a5" style={{background: "linear-gradient(to bottom, #ff33cc 0%, #000000 58%)"}}>
<div className="icon-lg text-primary m-b20">
<a href="#" className="icon-cell"></a>
</div>
<div className="icon-content">
<div className="step-number"></div>
<h4 className="wt-tilte text-white text-uppercase font-weight-500">Slot 4</h4>
<p className="text-white"><b>0.40 BNB</b></p>

<Userstatus slotn={4} />

{ profiledetails.activation_slot !== 4 ?
<button type="button" data-toggle="modal" data-target="#slot-form" value="4" onClick={slotpop}  className="site-button-secondry text-uppercase btn-block m-b10">Upgrade Slot</button>
:
<button type="button" className="site-button-secondry text-uppercase btn-block m-b10" >Upgrade Slot</button>
}

</div>
</div>
</div>

<div className="col-md-4 col-sm-4 step-number-block">
<div className="wt-icon-box-wraper  p-a30 center bg-white m-a5" style={{background: "linear-gradient(to bottom, #33ff99 0%, #000000 58%)"}}>
<div className="icon-lg text-primary m-b20">
<a href="#" className="icon-cell"></a>
</div>
<div className="icon-content">
<div className="step-number"></div>
<h4 className="wt-tilte text-white text-uppercase font-weight-500">Slot 5</h4>
<p className="text-white"><b>0.80 BNB</b></p>

<Userstatus slotn={5} />

{ profiledetails.activation_slot !== 5 ?
<button type="button" data-toggle="modal" data-target="#slot-form" value="5" onClick={slotpop}  className="site-button-secondry text-uppercase btn-block m-b10">Upgrade Slot</button>
:
<button type="button" className="site-button-secondry text-uppercase btn-block m-b10" >Upgrade Slot</button>
}

</div>
</div>
</div>

<div className="col-md-4 col-sm-4 step-number-block">
<div className="wt-icon-box-wraper  p-a30 center bg-white m-a5" style={{background: "linear-gradient(to bottom, #ffff00 0%, #000000 58%)"}}>
<div className="icon-lg text-primary m-b20">
<a href="#" className="icon-cell"></a>
</div>
<div className="icon-content">
<div className="step-number"></div>
<h4 className="wt-tilte text-white text-uppercase font-weight-500">Slot 6</h4>
<p className="text-white"><b>1.60 BNB</b></p>

<Userstatus slotn={6} />

{ profiledetails.activation_slot !== 6 ?
<button type="button" data-toggle="modal" data-target="#slot-form" value="6" onClick={slotpop}  className="site-button-secondry text-uppercase btn-block m-b10">Upgrade Slot</button>
:
<button type="button" className="site-button-secondry text-uppercase btn-block m-b10" >Upgrade Slot</button>
}

</div>
</div>
</div>
</div>

<div className="row mb-2">
<div className="col-md-4 col-sm-4 step-number-block" >
<div className="wt-icon-box-wraper  p-a30 center bg-white m-a5" style={{background: "linear-gradient(to bottom, #99ffcc 0%, #000000 58%)"}}>
<div className="icon-lg m-b20">
<a href="#" className="icon-cell"></a>
</div>
<div className="icon-content">
<div className="step-number active"></div>
<h4 className="wt-tilte text-white text-uppercase font-weight-500">Slot 7</h4>
<p className="text-white"><b>3.20 BNB</b></p>

<Userstatus slotn={7} />

{ profiledetails.activation_slot !== 7 ?
<button type="button" data-toggle="modal" data-target="#slot-form" value="7" onClick={slotpop}  className="site-button-secondry text-uppercase btn-block m-b10">Upgrade Slot</button>
:
<button type="button" className="site-button-secondry text-uppercase btn-block m-b10" >Upgrade Slot</button>
}

</div>
</div>
</div>

<div className="col-md-4 col-sm-4 step-number-block">
<div className="wt-icon-box-wraper  p-a30 center bg-white m-a5" style={{background: "linear-gradient(to bottom, #9966ff 0%, #000000 58%)"}}>
<div className="icon-lg m-b20">
<a href="#" className="icon-cell"></a>
</div>
<div className="icon-content">
<div className="step-number"></div>
<h4 className="wt-tilte text-white text-uppercase font-weight-500">Slot 8</h4>
<p className="text-white"><b>6.40 BNB</b></p>

<Userstatus slotn={8} />

{ profiledetails.activation_slot !== 8 ?
<button type="button" data-toggle="modal" data-target="#slot-form" value="8" onClick={slotpop}  className="site-button-secondry text-uppercase btn-block m-b10">Upgrade Slot</button>
:
<button type="button" className="site-button-secondry text-uppercase btn-block m-b10" >Upgrade Slot</button>
}

</div>
</div>
</div>

<div className="col-md-4 col-sm-4 step-number-block ">
<div className="wt-icon-box-wraper  p-a30 center bg-white m-a5" style={{background: "linear-gradient(to bottom, #ff5050 0%, #000000 58%)"}}>
<div className="icon-lg m-b20">
<a href="#" className="icon-cell"></a>
</div>
<div className="icon-content">
<div className="step-number active"></div>
<h4 className="wt-tilte text-white text-uppercase font-weight-500">Slot 9</h4>
<p className="text-white"><b>12.80 BNB</b></p>

<Userstatus slotn={9} />

{ profiledetails.activation_slot !== 9 ?
<button type="button" data-toggle="modal" data-target="#slot-form" value="9" onClick={slotpop}  className="site-button-secondry text-uppercase btn-block m-b10">Upgrade Slot</button>
:
<button type="button" className="site-button-secondry text-uppercase btn-block m-b10" >Upgrade Slot</button>
}

</div>
</div>
</div>
</div>

<div className="row  mb-2">
<div className="col-md-4 col-sm-4 step-number-block">
<div className="wt-icon-box-wraper  p-a30 center bg-white m-a5" style={{background: "linear-gradient(to bottom, #ffccff 0%, #000000 58%)"}}>
<div className="icon-lg text-primary m-b20">
<a href="#" className="icon-cell"></a>
</div>
<div className="icon-content">
<div className="step-number"></div>
<h4 className="wt-tilte text-white text-uppercase font-weight-500">Slot 10</h4>
<p className="text-white"><b>25.60 BNB</b></p>

<Userstatus slotn={10} />

{ profiledetails.activation_slot !== 10 ?
<button type="button" data-toggle="modal" data-target="#slot-form" value="10" onClick={slotpop}  className="site-button-secondry text-uppercase btn-block m-b10">Upgrade Slot</button>
:
<button type="button" className="site-button-secondry text-uppercase btn-block m-b10" >Upgrade Slot</button>
}

</div>
</div>
</div>
</div>

</div>

</div>
</div>


<div id="slot-form" className="modal fade " role="dialog">
<div className="modal-dialog modal-sm">
<div className="modal-content">
<div className="modal-header bg-primary">
<button type="button" className="close" data-dismiss="modal">&times;</button>
<h4 className="modal-title text-dark">Upgrade Slot {slot}</h4>
</div>
<div className="modal-body p-a30">

<center style={{fontSize:'20px',marginBottom:'10px'}}><b>{slotprice} BNB </b></center>

<div>
{slotprice>0 &&
<button type="button" onClick={() => sendTransaction()} className="site-button-secondry text-uppercase btn-block m-b10">Submit</button>
}
{isLoading && <div>Check Wallet</div>}
{isSuccess && <div>Transaction: {JSON.stringify(data)}</div>}
</div>

</div>
<div className="modal-footer text-center">
<div className="text-center"><img src="/assets/images/MS LOGO.png" style={{height: '75px'}} alt="" /></div>
</div>
</div>
</div>
</div>


    </div>
  )
}
