import React, { useEffect, useState } from 'react';
import Authuser from "../api/Authuser";

export default function Userdetails(props) {

    const {http} = Authuser();
    const [profiledetails,setProfiledetails] = useState([]);

    useEffect(()=>{
        getprofiledetails();
    },[]); 
    
    const getprofiledetails = () =>{
      http.get('/profile-details/'+props.userid).then(res=>{
        setProfiledetails(res.data);
      });
    };

  return (
    <div>

        <span>
            {profiledetails.name}<br/>
            { profiledetails.active_status === 1 &&
              <span class="text-dark badge bg-green">Active</span>
            }
        </span>

    </div>
  )
}
