import React, { useEffect, useState } from 'react';
import Authuser from "../api/Authuser";


export default function Refferal() {

const {http,murl} = Authuser();
const token = localStorage.getItem('mtoken');
const [profiledetails,setProfiledetails] = useState([]);

useEffect(()=>{
    getprofiledetails();
},[]); 

const getprofiledetails = () =>{
  http.get('/profile-details/'+token).then(res=>{
      setProfiledetails(res.data);
  });
};


  return (
    <div>


<div className="container">

<div className="row mb-3">
<div className="col-md-12">
        <div className="card">
        <div className="card-header text-center"><h4 className="text-danger">Share Refferal Link</h4></div>
        <div className="card-body mt-2" style={{padding:'10px'}}>
        <input type="text" value={`${murl}register/${profiledetails.id}`}  className="form-control"  readonly="readonly" />
        <div id="todo-container" style={{ display: 'flex;justify-content: space-between'}}>
        <div className="error-social "> 
        <a href={`http://www.facebook.com/sharer.php?p[url]=${murl}register/${profiledetails.id}`} target="_blank" style={{ padding: '5px',  fontSize: '20px', color:'#25d366'}}><i className="fa fa-facebook-official fa-2x"></i>
        </a> 
        <a href={`https://api.whatsapp.com/send?text=${murl}register/${profiledetails.id}`} target="_blank" style={{ padding: '5px',  fontSize: '20px', color:'#25d366'}}><i className="fa fa-whatsapp fa-2x"></i></a>
        <a href={`https://twitter.com/intent/tweet?text=${murl}register/${profiledetails.id}`} target="_blank" style={{ padding: '5px',  fontSize: '20px', color:'#25d366'}}><i className="fa fa-twitter fa-2x"></i></a>
        </div>
        </div>
        </div>
        </div>
</div>
</div>

</div>



    </div>
  )
}
