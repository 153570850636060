import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Authuser from "../api/Authuser";

export default function Register() {

const {sponsorid} = useParams();
const {http,setToken} = Authuser();
const [name,setName] = useState(null);
const [errorname,setErrorname] = useState(null);
const [loader,setLoader] = useState('off');
const [successmsg,setSuccessmsg] = useState(null);

const submitForm = () => {
    setLoader('on');
    http.post('/register',{name:name,sponsorid:sponsorid}).then(res=>{
      if(res.data.status==422){
        setErrorname(res.data.error.name);
      }else{
        setSuccessmsg(res.data.successmsg);
        setErrorname('');
        setToken(res.data.loginid);
      }
      setLoader('off');
    });
  }

  return (
    <div>


<div className="container">
<h2 style={{textAlign: 'center'}}>Enter Your Full Name</h2>
<div id="Register-form" style={{color: 'blue',background: 'linear-gradient(to right bottom,#fff,#eee)',
fontFamily: "'Roboto', sans-serif",
textAlign: 'center',  padding: '25px 25px 25px',  borderRadius: '35px', boxShadow: '5px 5px 10px rgba(0,0,0,0.3)', position: 'relative',
}}>

<form>
<div className="form-group">
    <div className="input-group">
        <span className="input-group-addon"><i className="fa fa-user"></i></span>
        <input className="form-control" onChange={e=>setName(e.target.value)} placeholder="Enter Your Full Name" type="text"/>
        <span style={{color:'red'}}>{errorname}</span>
    </div>
</div>
<button type="button" onClick={submitForm} className="site-button-secondry text-uppercase btn-block m-b10">
{loader==='off' &&
<span>Submit</span>
}
{loader==='on' &&
<span style={{color:'green'}}>Please Wait...</span>
}
</button>
{successmsg!=null &&
<div className='alert alert-success'>{successmsg}</div>
}

</form>

</div>
</div>


    </div>
  )
}
