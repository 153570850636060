import React, { useEffect, useState } from 'react';
import {Helmet} from "react-helmet";
import Authuser from "../api/Authuser";

export default function Dashboard() {

  const {http} = Authuser();
  const token = localStorage.getItem('mtoken');
  const [profiledetails,setProfiledetails] = useState([]);

  useEffect(()=>{
    getprofiledetails();
},[]); 

const getprofiledetails = () =>{
  http.get('/profile-details/'+token).then(res=>{
    setProfiledetails(res.data);
  });
};

  return (
    <div>

<Helmet>
<script src="/assets/js/jquery-1.12.4.min.js"></script>
    <script src="/assets/js/bootstrap.min.js"></script>
    <script src="/assets/js/bootstrap-select.min.js"></script>
    <script src="/assets/js/jquery.bootstrap-touchspin.min.js"></script>
    <script   src="/assets/js/magnific-popup.min.js"></script>
    <script   src="/assets/js/waypoints.min.js"></script>
    <script   src="/assets/js/counterup.min.js"></script>
    <script   src="/assets/js/waypoints-sticky.min.js"></script>
    <script  src="/assets/js/isotope.pkgd.min.js"></script>
    <script   src="/assets/js/owl.carousel.min.js"></script>
    <script   src="/assets/js/stellar.min.js"></script> 
    <script   src="/assets/js/scrolla.min.js"></script>
    <script   src="/assets/js/custom.js"></script>
    <script   src="/assets/js/shortcode.js"></script>
    <script   src="/assets/js/switcher.js"></script>
    <script  src="/assets/js/jquery.bgscroll.js"></script>
    <script  src="/assets/js/tickerNews.min.js"></script>
    
    <script  src="/assets/plugins/revolution/revolution/js/jquery.themepunch.tools.min.js"></script>
    <script  src="/assets/plugins/revolution/revolution/js/jquery.themepunch.revolution.min.js"></script>
    <script  src="/assets/plugins/revolution/revolution/js/extensions/revolution-plugin.js"></script>
    <script   src="/assets/js/rev-script-2.js"></script>
</Helmet>

{/* <!-- SLIDER START --> */}
<div className="main-slider style-two default-banner">
          <div className="tp-banner-container">
            <div className="tp-banner">
              {/* START REVOLUTION SLIDER 5.4.1 */}
              <div
                id="rev_slider_1014_1_wrapper"
                className="rev_slider_wrapper fullscreen-container"
                data-alias="typewriter-effect"
                data-source="gallery"
              >
                <div
                  id="rev_slider_1014_1"
                  className="rev_slider fullscreenbanner"
                  style={{ display: "none" }}
                  data-version="5.4.1"
                >
                  <ul>
                    {/* SLIDE 1 */}
                    <li
                      data-index="rs-1000"
                      data-transition="slidingoverlayhorizontal"
                      data-slotamount="default"
                      data-hideafterloop={0}
                      data-hideslideonmobile="off"
                      data-easein="default"
                      data-easeout="default"
                      data-masterspeed="default"
                      data-thumb="/assets/images/main-slider/slider1/slide1.jpg"
                      data-rotate={0}
                      data-saveperformance="off"
                      data-title="Slide"
                      data-param1=""
                      data-param2=""
                      data-param3=""
                      data-param4=""
                      data-param5=""
                      data-param6=""
                      data-param7=""
                      data-param8=""
                      data-param9=""
                      data-param10=""
                      data-description=""
                    >
                      {/* MAIN IMAGE */}
                      <img
                        src="/assets/images/main-slider/slider1/slide1.jpg"
                        alt=""
                        data-bgposition="center bottom"
                        data-bgfit="cover"
                        data-bgrepeat="no-repeat"
                        className="rev-slidebg"
                        data-no-retina=""
                      />
                      {/* LAYERS */}
                      {/* LAYER NR. 1 [ for overlay ] */}
                      <div
                        className="tp-caption tp-shape tp-shapewrapper "
                        id="slide-100-layer-1"
                        data-x="['center','center','center','center']"
                        data-hoffset="['0','0','0','0']"
                        data-y="['middle','middle','middle','middle']"
                        data-voffset="['0','0','0','0']"
                        data-width="full"
                        data-height="full"
                        data-whitespace="nowrap"
                        data-type="shape"
                        data-basealign="slide"
                        data-responsive_offset="off"
                        data-responsive="off"
                        data-frames='[
                              {"from":"opacity:0;","speed":1000,"to":"o:1;","delay":0,"ease":"Power4.easeOut"},
                              {"delay":"wait","speed":1000,"to":"opacity:0;","ease":"Power4.easeOut"}
                              ]'
                        data-textalign="['left','left','left','left']"
                        data-paddingtop="[0,0,0,0]"
                        data-paddingright="[0,0,0,0]"
                        data-paddingbottom="[0,0,0,0]"
                        data-paddingleft="[0,0,0,0]"
                        style={{
                          zIndex: 12,
                          backgroundColor: "rgba(0, 0, 0, 0.3)",
                          borderColor: "rgba(0, 0, 0, 0)",
                          borderWidth: 0,
                        }}
                      ></div>
                      {/* LAYER NR. 2 [ for title ] */}
                      <div
                        className="tp-caption   tp-resizeme"
                        id="slide-100-layer-2"
                        data-x="['left','left','left','left']"
                        data-hoffset="['30','30','30','30']"
                        data-y="['top','top','top','top']"
                        data-voffset="['208','308','308','308']"
                        data-fontsize="['60','60','60','60']"
                        data-lineheight="['110','110','110','110']"
                        data-width="['6','6','6','6']"
                        data-height="['110,'110','110','110']"
                        data-whitespace="['normal','normal','normal','normal']"
                        data-type="text"
                        data-responsive_offset="on"
                        data-frames='[
                              {"from":"y:100px(R);opacity:0;","speed":2000,"to":"o:1;","delay":500,"ease":"Power4.easeOut"},
                              {"delay":"wait","speed":1000,"to":"y:-50px;opacity:0;","ease":"Power2.easeInOut"}
                              ]'
                        data-textalign="['left','left','left','left']"
                        data-paddingtop="[0,0,0,0]"
                        data-paddingright="[0,0,0,0]"
                        data-paddingbottom="[0,0,0,0]"
                        data-paddingleft="[0,0,0,0]"
                        style={{ zIndex: 13, whiteSpace: "normal" }}
                      >
                        <div className="bg-primary">&nbsp;</div>
                      </div>
                      {/* LAYER NR. 3 [ for title ] */}
                      <div
                        className="tp-caption   tp-resizeme"
                        id="slide-100-layer-3"
                        data-x="['left','left','left','left']"
                        data-hoffset="['60','60','60','100']"
                        data-y="['top','top','top','top']"
                        data-voffset="['200','300','300','300']"
                        data-fontsize="['60','60','60','45']"
                        data-lineheight="['65','65','65','65']"
                        data-width="['700','700','700','700']"
                        data-height="['none','none','none','none']"
                        data-whitespace="['normal','normal','normal','normal']"
                        data-type="text"
                        data-responsive_offset="on"
                        data-frames='[
                              {"from":"y:100px(R);opacity:0;","speed":2000,"to":"o:1;","delay":500,"ease":"Power4.easeOut"},
                              {"delay":"wait","speed":1000,"to":"y:-50px;opacity:0;","ease":"Power2.easeInOut"}
                              ]'
                        data-textalign="['left','left','left','left']"
                        data-paddingtop="[0,0,0,0]"
                        data-paddingright="[0,0,0,0]"
                        data-paddingbottom="[0,0,0,0]"
                        data-paddingleft="[0,0,0,0]"
                        style={{
                          zIndex: 13,
                          whiteSpace: "normal",
                          fontWeight: 700,
                          color: "rgb(75, 57, 65)",
                          borderWidth: 0,
                        }}
                      >
                        <div
                          style={{
                            fontFamily: '"Poppins", sans-serif',
                            textTransform: "uppercase",
                          }}
                        >
                          <span
                            className="text-white"
                            style={{ paddingRight: 10 }}
                          >
                            The most
                          </span>
                          <span className="text-primary">Secure</span>
                        </div>
                      </div>
                      {/* LAYER NR. 4 [ for title ] */}
                      <div
                        className="tp-caption   tp-resizeme"
                        id="slide-100-layer-4"
                        data-x="['left','left','left','left']"
                        data-hoffset="['60','60','60','100']"
                        data-y="['top','top','top','top']"
                        data-voffset="['260','360','360','360']"
                        data-fontsize="['58','58','58','45']"
                        data-lineheight="['75','75','75','75']"
                        data-width="['700','700','700','700']"
                        data-height="['none','none','none','none']"
                        data-whitespace="['normal','normal','normal','normal']"
                        data-type="text"
                        data-responsive_offset="on"
                        data-frames='[
                              {"from":"y:100px(R);opacity:0;","speed":2000,"to":"o:1;","delay":1000,"ease":"Power4.easeOut"},
                              {"delay":"wait","speed":1000,"to":"y:-50px;opacity:0;","ease":"Power2.easeInOut"}
                              ]'
                        data-textalign="['left','left','left','left']"
                        data-paddingtop="[0,0,0,0]"
                        data-paddingright="[0,0,0,0]"
                        data-paddingbottom="[0,0,0,0]"
                        data-paddingleft="[0,0,0,0]"
                        style={{
                          zIndex: 13,
                          whiteSpace: "normal",
                          fontWeight: 700,
                          borderWidth: 0,
                        }}
                      >
                        <div
                          style={{
                            fontFamily: '"Poppins", sans-serif',
                            textTransform: "uppercase",
                          }}
                        >
                          <span
                            className="text-primary"
                            style={{ paddingRight: 10 }}
                          >
                            Crypto
                          </span>
                          <span className="text-white">Currency</span>
                        </div>
                      </div>
                      {/* LAYER NR. 5 [ for paragraph] */}
                      <div
                        className="tp-caption  tp-resizeme"
                        id="slide-100-layer-5"
                        data-x="['left','left','left','left']"
                        data-hoffset="['30','30','30','100']"
                        data-y="['top','top','top','top']"
                        data-voffset="['340','440','440','440']"
                        data-fontsize="['18','18','18','30']"
                        data-lineheight="['30','30','30','40']"
                        data-width="['650','650','650','650']"
                        data-height="['none','none','none','none']"
                        data-whitespace="['normal','normal','normal','normal']"
                        data-type="text"
                        data-responsive_offset="on"
                        data-frames='[

                              {"from":"y:100px(R);opacity:0;","speed":2000,"to":"o:1;","delay":1500,"ease":"Power4.easeOut"},
                              {"delay":"wait","speed":1000,"to":"y:-50px;opacity:0;","ease":"Power2.easeInOut"}
                              ]'
                        data-textalign="['left','left','left','left']"
                        data-paddingtop="[0,0,0,0]"
                        data-paddingright="[0,0,0,0]"
                        data-paddingbottom="[0,0,0,0]"
                        data-paddingleft="[0,0,0,0]"
                        style={{
                          zIndex: 13,
                          fontWeight: 500,
                          color: "#fff",
                          borderWidth: 0,
                        }}
                      >
                        <span style={{ fontFamily: '"Poppins", sans-serif' }}>
                          Lorem Ipsum is simply dummy text of the printing and
                          typesetting industry. Lorem Ipsum has been end
                        </span>
                      </div>
                      {/* LAYER NR. 6 [ for see all service botton ] */}
                      <div
                        className="tp-caption tp-resizeme"
                        id="slide-100-layer-6"
                        data-x="['left','left','left','left']"
                        data-hoffset="['30','30','30','100']"
                        data-y="['top','top','top','top']"
                        data-voffset="['430','530','530','600']"
                        data-lineheight="['none','none','none','none']"
                        data-width="['300','300','300','300']"
                        data-height="['none','none','none','none']"
                        data-whitespace="['normal','normal','normal','normal']"
                        data-type="text"
                        data-responsive_offset="on"
                        data-frames='[ 
                              {"from":"y:100px(R);opacity:0;","speed":2000,"to":"o:1;","delay":2000,"ease":"Power4.easeOut"},
                              {"delay":"wait","speed":1000,"to":"y:-50px;opacity:0;","ease":"Power2.easeInOut"}
                              ]'
                        data-textalign="['left','left','left','left']"
                        data-paddingtop="[0,0,0,0]"
                        data-paddingright="[0,0,0,0]"
                        data-paddingbottom="[0,0,0,0]"
                        data-paddingleft="[0,0,0,0]"
                        style={{ zIndex: 13, textTransform: "uppercase" }}
                      >
                        <a
                          href="javascript:;"
                          className="site-button slider-btn-left"
                        >
                          Contact Us
                        </a>
                      </div>
                      {/* LAYER NR. 7 [ for more detail botton ] */}
                      <div
                        className="tp-caption tp-resizeme"
                        id="slide-100-layer-7"
                        data-x="['left','left','left','left']"
                        data-hoffset="['220','220','220','320']"
                        data-y="['top','top','top','top']"
                        data-voffset="['430','530','530','600']"
                        data-lineheight="['none','none','none','none']"
                        data-width="['300','300','300','300']"
                        data-height="['none','none','none','none']"
                        data-whitespace="['normal','normal','normal','normal']"
                        data-type="text"
                        data-responsive_offset="on"
                        data-frames='[ 
                              {"from":"y:100px(R);opacity:0;","speed":2000,"to":"o:1;","delay":2000,"ease":"Power4.easeOut"},
                              {"delay":"wait","speed":1000,"to":"y:-50px;opacity:0;","ease":"Power2.easeInOut"}
                              ]'
                        data-textalign="['left','left','left','left']"
                        data-paddingtop="[0,0,0,0]"
                        data-paddingright="[0,0,0,0]"
                        data-paddingbottom="[0,0,0,0]"
                        data-paddingleft="[0,0,0,0]"
                        style={{
                          zIndex: 13,
                          textTransform: "uppercase",
                          fontWeight: 500,
                        }}
                      >
                        <a
                          href="javascript:;"
                          className=" site-button white slider-btn-right"
                        >
                          Join Us
                        </a>
                      </div>
                    </li>
                    {/* SLIDE 2 */}
                    <li
                      data-index="rs-1001"
                      data-transition="slidingoverlayhorizontal"
                      data-slotamount="default"
                      data-hideafterloop={0}
                      data-hideslideonmobile="off"
                      data-easein="default"
                      data-easeout="default"
                      data-masterspeed="default"
                      data-thumb="/assets/images/main-slider/slider1/slide2.jpg"
                      data-rotate={0}
                      data-saveperformance="off"
                      data-title="Slide"
                      data-param1=""
                      data-param2=""
                      data-param3=""
                      data-param4=""
                      data-param5=""
                      data-param6=""
                      data-param7=""
                      data-param8=""
                      data-param9=""
                      data-param10=""
                      data-description=""
                    >
                      {/* MAIN IMAGE */}
                      <img
                        src="/assets/images/main-slider/slider1/slide2.jpg"
                        alt=""
                        data-bgposition="center bottom"
                        data-bgfit="cover"
                        data-bgrepeat="no-repeat"
                        className="rev-slidebg"
                        data-no-retina=""
                      />
                      {/* LAYERS */}
                      {/* LAYER NR. 1 [ for overlay ] */}
                      <div
                        className="tp-caption tp-shape tp-shapewrapper "
                        id="slide-101-layer-1"
                        data-x="['center','center','center','center']"
                        data-hoffset="['0','0','0','0']"
                        data-y="['middle','middle','middle','middle']"
                        data-voffset="['0','0','0','0']"
                        data-width="full"
                        data-height="full"
                        data-whitespace="nowrap"
                        data-type="shape"
                        data-basealign="slide"
                        data-responsive_offset="off"
                        data-responsive="off"
                        data-frames='[
                              {"from":"opacity:0;","speed":1000,"to":"o:1;","delay":0,"ease":"Power4.easeOut"},
                              {"delay":"wait","speed":1000,"to":"opacity:0;","ease":"Power4.easeOut"}
                              ]'
                        data-textalign="['left','left','left','left']"
                        data-paddingtop="[0,0,0,0]"
                        data-paddingright="[0,0,0,0]"
                        data-paddingbottom="[0,0,0,0]"
                        data-paddingleft="[0,0,0,0]"
                        style={{
                          zIndex: 12,
                          backgroundColor: "rgba(0, 0, 0, 0.3)",
                          borderColor: "rgba(0, 0, 0, 0)",
                          borderWidth: 0,
                        }}
                      ></div>
                      {/* LAYER NR. 2 [ for title ] */}
                      <div
                        className="tp-caption   tp-resizeme"
                        id="slide-101-layer-2"
                        data-x="['left','left','left','left']"
                        data-hoffset="['30','30','30','30']"
                        data-y="['top','top','top','top']"
                        data-voffset="['208','308','308','308']"
                        data-fontsize="['60','60','60','60']"
                        data-lineheight="['110','110','110','110']"
                        data-width="['6','6','6','6']"
                        data-height="['110,'110','110','110']"
                        data-whitespace="['normal','normal','normal','normal']"
                        data-type="text"
                        data-responsive_offset="on"
                        data-frames='[
                              {"from":"y:100px(R);opacity:0;","speed":2000,"to":"o:1;","delay":500,"ease":"Power4.easeOut"},
                              {"delay":"wait","speed":1000,"to":"y:-50px;opacity:0;","ease":"Power2.easeInOut"}
                              ]'
                        data-textalign="['left','left','left','left']"
                        data-paddingtop="[0,0,0,0]"
                        data-paddingright="[0,0,0,0]"
                        data-paddingbottom="[0,0,0,0]"
                        data-paddingleft="[0,0,0,0]"
                        style={{ zIndex: 13, whiteSpace: "normal" }}
                      >
                        <div className="bg-primary">&nbsp;</div>
                      </div>
                      {/* LAYER NR. 3 [ for title ] */}
                      <div
                        className="tp-caption   tp-resizeme"
                        id="slide-101-layer-3"
                        data-x="['left','left','left','left']"
                        data-hoffset="['60','60','60','100']"
                        data-y="['top','top','top','top']"
                        data-voffset="['200','300','300','300']"
                        data-fontsize="['60','60','60','45']"
                        data-lineheight="['65','65','65','65']"
                        data-width="['700','700','700','700']"
                        data-height="['none','none','none','none']"
                        data-whitespace="['normal','normal','normal','normal']"
                        data-type="text"
                        data-responsive_offset="on"
                        data-frames='[
                              {"from":"y:100px(R);opacity:0;","speed":2000,"to":"o:1;","delay":500,"ease":"Power4.easeOut"},
                              {"delay":"wait","speed":1000,"to":"y:-50px;opacity:0;","ease":"Power2.easeInOut"}
                              ]'
                        data-textalign="['left','left','left','left']"
                        data-paddingtop="[0,0,0,0]"
                        data-paddingright="[0,0,0,0]"
                        data-paddingbottom="[0,0,0,0]"
                        data-paddingleft="[0,0,0,0]"
                        style={{
                          zIndex: 13,
                          whiteSpace: "normal",
                          fontWeight: 700,
                          color: "rgb(75, 57, 65)",
                          borderWidth: 0,
                        }}
                      >
                        <div
                          style={{
                            fontFamily: '"Poppins", sans-serif',
                            textTransform: "uppercase",
                          }}
                        >
                          <span
                            className="text-white"
                            style={{ paddingRight: 10 }}
                          >
                            The most
                          </span>
                          <span className="text-primary">Secure</span>
                        </div>
                      </div>
                      {/* LAYER NR. 4 [ for title ] */}
                      <div
                        className="tp-caption   tp-resizeme"
                        id="slide-101-layer-4"
                        data-x="['left','left','left','left']"
                        data-hoffset="['60','60','60','100']"
                        data-y="['top','top','top','top']"
                        data-voffset="['260','360','360','360']"
                        data-fontsize="['58','58','58','45']"
                        data-lineheight="['75','75','75','75']"
                        data-width="['700','700','700','700']"
                        data-height="['none','none','none','none']"
                        data-whitespace="['normal','normal','normal','normal']"
                        data-type="text"
                        data-responsive_offset="on"
                        data-frames='[
                              {"from":"y:100px(R);opacity:0;","speed":2000,"to":"o:1;","delay":1000,"ease":"Power4.easeOut"},
                              {"delay":"wait","speed":1000,"to":"y:-50px;opacity:0;","ease":"Power2.easeInOut"}
                              ]'
                        data-textalign="['left','left','left','left']"
                        data-paddingtop="[0,0,0,0]"
                        data-paddingright="[0,0,0,0]"
                        data-paddingbottom="[0,0,0,0]"
                        data-paddingleft="[0,0,0,0]"
                        style={{
                          zIndex: 13,
                          whiteSpace: "normal",
                          fontWeight: 700,
                          borderWidth: 0,
                        }}
                      >
                        <div
                          style={{
                            fontFamily: '"Poppins", sans-serif',
                            textTransform: "uppercase",
                          }}
                        >
                          <span
                            className="text-primary"
                            style={{ paddingRight: 10 }}
                          >
                            Crypto
                          </span>
                          <span className="text-white">Currency</span>
                        </div>
                      </div>
                      {/* LAYER NR. 5 [ for paragraph] */}
                      <div
                        className="tp-caption  tp-resizeme"
                        id="slide-101-layer-5"
                        data-x="['left','left','left','left']"
                        data-hoffset="['30','30','30','100']"
                        data-y="['top','top','top','top']"
                        data-voffset="['340','440','440','440']"
                        data-fontsize="['18','18','18','30']"
                        data-lineheight="['30','30','30','40']"
                        data-width="['650','650','650','650']"
                        data-height="['none','none','none','none']"
                        data-whitespace="['normal','normal','normal','normal']"
                        data-type="text"
                        data-responsive_offset="on"
                        data-frames='[
                              {"from":"y:100px(R);opacity:0;","speed":2000,"to":"o:1;","delay":1500,"ease":"Power4.easeOut"},
                              {"delay":"wait","speed":1000,"to":"y:-50px;opacity:0;","ease":"Power2.easeInOut"}
                              ]'
                        data-textalign="['left','left','left','left']"
                        data-paddingtop="[0,0,0,0]"
                        data-paddingright="[0,0,0,0]"
                        data-paddingbottom="[0,0,0,0]"
                        data-paddingleft="[0,0,0,0]"
                        style={{
                          zIndex: 13,
                          fontWeight: 500,
                          color: "#fff",
                          borderWidth: 0,
                        }}
                      >
                        <span style={{ fontFamily: '"Poppins", sans-serif' }}>
                          Lorem Ipsum is simply dummy text of the printing and
                          typesetting industry. Lorem Ipsum has been end
                        </span>
                      </div>
                      {/* LAYER NR. 6 [ for see all service botton ] */}
                      <div
                        className="tp-caption tp-resizeme"
                        id="slide-101-layer-6"
                        data-x="['left','left','left','left']"
                        data-hoffset="['30','30','30','100']"
                        data-y="['top','top','top','top']"
                        data-voffset="['430','530','530','600']"
                        data-lineheight="['none','none','none','none']"
                        data-width="['300','300','300','300']"
                        data-height="['none','none','none','none']"
                        data-whitespace="['normal','normal','normal','normal']"
                        data-type="text"
                        data-responsive_offset="on"
                        data-frames='[ 
                              {"from":"y:100px(R);opacity:0;","speed":2000,"to":"o:1;","delay":2000,"ease":"Power4.easeOut"},
                              {"delay":"wait","speed":1000,"to":"y:-50px;opacity:0;","ease":"Power2.easeInOut"}
                              ]'
                        data-textalign="['left','left','left','left']"
                        data-paddingtop="[0,0,0,0]"
                        data-paddingright="[0,0,0,0]"
                        data-paddingbottom="[0,0,0,0]"
                        data-paddingleft="[0,0,0,0]"
                        style={{ zIndex: 13, textTransform: "uppercase" }}
                      >
                        <a
                          href="javascript:;"
                          className="site-button slider-btn-left"
                        >
                          Contact Us
                        </a>
                      </div>
                      {/* LAYER NR. 7 [ for more detail botton ] */}
                      <div
                        className="tp-caption tp-resizeme"
                        id="slide-101-layer-7"
                        data-x="['left','left','left','left']"
                        data-hoffset="['220','220','220','320']"
                        data-y="['top','top','top','top']"
                        data-voffset="['430','530','530','600']"
                        data-lineheight="['none','none','none','none']"
                        data-width="['300','300','300','300']"
                        data-height="['none','none','none','none']"
                        data-whitespace="['normal','normal','normal','normal']"
                        data-type="text"
                        data-responsive_offset="on"
                        data-frames='[ 
                              {"from":"y:100px(R);opacity:0;","speed":2000,"to":"o:1;","delay":2000,"ease":"Power4.easeOut"},
                              {"delay":"wait","speed":1000,"to":"y:-50px;opacity:0;","ease":"Power2.easeInOut"}
                              ]'
                        data-textalign="['left','left','left','left']"
                        data-paddingtop="[0,0,0,0]"
                        data-paddingright="[0,0,0,0]"
                        data-paddingbottom="[0,0,0,0]"
                        data-paddingleft="[0,0,0,0]"
                        style={{
                          zIndex: 13,
                          textTransform: "uppercase",
                          fontWeight: 500,
                        }}
                      >
                        <a
                          href="javascript:;"
                          className=" site-button white slider-btn-right"
                        >
                          Join Us
                        </a>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              {/* END REVOLUTION SLIDER */}
            </div>
          </div>
        </div>
        {/* <!-- SLIDER END --> */}


<div className="section-full  p-t80 p-b80 bg-gray">
<div className="container">

<div className="section-head text-center">
<span className="wt-title-subline font-22 text-gray-dark m-b15">Join As A Member</span>
<h2 className="text-uppercase">Save Your Money</h2>
<div className="wt-separator-outer"><div className="wt-separator bg-primary"></div></div>
<p>
If you know what exactly you are looking for, then we have the right thing for you! Now save your money by spending on exactly what you are looking to learn. We make it really simple for you to choose what you need and customise your learning process for an efficient and effective learning process! Save time and money while developing solid skills and knowledge!!
</p>
</div>

{/* <div className="section-content no-col-gap">
<div className="row mb-2">

<div className="col-md-4 col-sm-4 step-number-block" >
<div className="wt-icon-box-wraper  p-a30 center bg-white m-a5" style={{background: "linear-gradient(to bottom, #ff6600 0%, #000000 58%)"}}>
<div className="icon-lg text-primary m-b20">
<a href="#" className="icon-cell"></a>
</div>
<div className="icon-content">
<div className="step-number"></div>
<h4 className="wt-tilte text-white text-uppercase font-weight-500">Slot 1</h4>
<p className="text-white">$10</p>
{ profiledetails.activation_slot === 1 &&
              <span className="text-white badge bg-dark">Status : Active</span>
              }
</div>
</div>
</div>

<div className="col-md-4 col-sm-4 step-number-block">
<div className="wt-icon-box-wraper  p-a30 center bg-white m-a5 " style={{background: "linear-gradient(to bottom, #33ccff 0%, #000000 58%)"}}>
<div className="icon-lg m-b20 text-primary">
<a href="#" className="icon-cell"></a>
</div>
<div className="icon-content">
<div className="step-number active"></div>
<h4 className="wt-tilte text-white text-uppercase font-weight-500">Slot 2</h4>
<p className="text-white">$20</p>
{ profiledetails.activation_slot === 2 &&
              <span className="text-white badge bg-dark">Status : Active</span>
              } 
</div>
</div>
</div>

<div className="col-md-4 col-sm-4 step-number-block">
<div className="wt-icon-box-wraper  p-a30 center bg-white m-a5" style={{background: "linear-gradient(to bottom, #99ff99 0%, #000000 58%)"}}>
<div className="icon-lg text-primary m-b20">
<a href="#" className="icon-cell"></a>
</div>
<div className="icon-content">
<div className="step-number"></div>
<h4 className="wt-tilte text-white text-uppercase font-weight-500">Slot 3</h4>
<p className="text-white">$40</p>
{ profiledetails.activation_slot === 3 &&
              <span className="text-white badge bg-dark">Status : Active</span>
              }
</div>
</div>
</div>
</div>


<div className="row  mb-2">
<div className="col-md-4 col-sm-4 step-number-block">
<div className="wt-icon-box-wraper  p-a30 center bg-white m-a5" style={{background: "linear-gradient(to bottom, #ff33cc 0%, #000000 58%)"}}>
<div className="icon-lg text-primary m-b20">
<a href="#" className="icon-cell"></a>
</div>
<div className="icon-content">
<div className="step-number"></div>
<h4 className="wt-tilte text-white text-uppercase font-weight-500">Slot 4</h4>
<p className="text-white">$80</p>
{ profiledetails.activation_slot === 4 &&
              <span className="text-white badge bg-dark">Status : Active</span>
              }
</div>
</div>
</div>

<div className="col-md-4 col-sm-4 step-number-block">
<div className="wt-icon-box-wraper  p-a30 center bg-white m-a5" style={{background: "linear-gradient(to bottom, #33ff99 0%, #000000 58%)"}}>
<div className="icon-lg text-primary m-b20">
<a href="#" className="icon-cell"></a>
</div>
<div className="icon-content">
<div className="step-number"></div>
<h4 className="wt-tilte text-white text-uppercase font-weight-500">Slot 5</h4>
<p className="text-white">$160</p>
{ profiledetails.activation_slot === 5 &&
              <span className="text-white badge bg-dark">Status : Active</span>
              }
</div>
</div>
</div>

<div className="col-md-4 col-sm-4 step-number-block">
<div className="wt-icon-box-wraper  p-a30 center bg-white m-a5" style={{background: "linear-gradient(to bottom, #ffff00 0%, #000000 58%)"}}>
<div className="icon-lg text-primary m-b20">
<a href="#" className="icon-cell"></a>
</div>
<div className="icon-content">
<div className="step-number"></div>
<h4 className="wt-tilte text-white text-uppercase font-weight-500">Slot 6</h4>
<p className="text-white">$320</p>
{ profiledetails.activation_slot === 6 &&
              <span className="text-white badge bg-dark">Status : Active</span>
              }
</div>
</div>
</div>
</div>

<div className="row mb-2">
<div className="col-md-4 col-sm-4 step-number-block" >
<div className="wt-icon-box-wraper  p-a30 center bg-white m-a5" style={{background: "linear-gradient(to bottom, #99ffcc 0%, #000000 58%)"}}>
<div className="icon-lg m-b20">
<a href="#" className="icon-cell"></a>
</div>
<div className="icon-content">
<div className="step-number active"></div>
<h4 className="wt-tilte text-white text-uppercase font-weight-500">Slot 7</h4>
<p className="text-white">$640</p>
{ profiledetails.activation_slot === 7 &&
              <span className="text-white badge bg-dark">Status : Active</span>
              }
</div>
</div>
</div>

<div className="col-md-4 col-sm-4 step-number-block">
<div className="wt-icon-box-wraper  p-a30 center bg-white m-a5" style={{background: "linear-gradient(to bottom, #9966ff 0%, #000000 58%)"}}>
<div className="icon-lg m-b20">
<a href="#" className="icon-cell"></a>
</div>
<div className="icon-content">
<div className="step-number"></div>
<h4 className="wt-tilte text-white text-uppercase font-weight-500">Slot 8</h4>
<p className="text-white">$1280</p>
{ profiledetails.activation_slot === 8 &&
              <span className="text-white badge bg-dark">Status : Active</span>
              }
</div>
</div>
</div>

<div className="col-md-4 col-sm-4 step-number-block ">
<div className="wt-icon-box-wraper  p-a30 center bg-white m-a5" style={{background: "linear-gradient(to bottom, #ff5050 0%, #000000 58%)"}}>
<div className="icon-lg m-b20">
<a href="#" className="icon-cell"></a>
</div>
<div className="icon-content">
<div className="step-number active"></div>
<h4 className="wt-tilte text-white text-uppercase font-weight-500">Slot 9</h4>
<p className="text-white">$2560</p>
{ profiledetails.activation_slot === 9 &&
              <span className="text-white badge bg-dark">Status : Active</span>
              }
</div>
</div>
</div>
</div>

<div className="row  mb-2">
<div className="col-md-4 col-sm-4 step-number-block">
<div className="wt-icon-box-wraper  p-a30 center bg-white m-a5" style={{background: "linear-gradient(to bottom, #ffccff 0%, #000000 58%)"}}>
<div className="icon-lg text-primary m-b20">
<a href="#" className="icon-cell"></a>
</div>
<div className="icon-content">
<div className="step-number"></div>
<h4 className="wt-tilte text-white text-uppercase font-weight-500">Slot 10</h4>
<p className="text-white">$5120</p>
{ profiledetails.activation_slot === 10 &&
              <span className="text-white badge bg-dark">Status : Active</span>
              }
</div>
</div>
</div>
</div>

</div> */}
</div>
</div>


{/* <!-- ABOUT COMPANY SECTION START -->            */}
<div className="section-full home-about-section p-t80 bg-no-repeat bg-bottom-right"  style={{backgroundImage:"url('/assets/images/background/bg-coin.png')"}}>
<div className="container-fluid ">
<div className="row">
<div className="col-md-6">
<div className="wt-box text-right">
<img src="/assets/images/background/bg-laptop.png" alt=""/> 
</div>
</div>
<div className="col-md-6">
<div className="wt-right-part p-b80">
<div className="section-head text-left">
<span className="wt-title-subline font-22 text-gray-dark m-b15">What is bitcoin</span>
<h2 className="text-uppercase">Why blockchain is important:</h2>
<div className="wt-separator-outer"><div className="wt-separator bg-primary"></div></div>
</div>
<div className="section-content">
<div className="wt-box">
    <p>
        <strong> Business runs on information. The faster it’s received and the more accurate it is, the better. Blockchain is ideal for delivering that information because it provides immediate, shared and completely transparent information stored on an immutable ledger that can be accessed only by permissioned network members. A blockchain network can track orders, payments, accounts, production and much more. And because members share a single view of the truth, you can see all details of a transaction end to end, giving you greater confidence, as well as new efficiencies and opportunities.
        </strong>
    </p>
</div>
</div>                                	
</div>
</div>
</div>
</div>
</div>
{/* <!-- ABOUT COMPANY SECTION  END -->  */}


{/* <!-- CONTACT US SECTION END  -->          */}
<div className="section-full overlay-wraper bg-center bg-cover bg-no-repeat  bg-gray" style={{backgroundImage:"url('/assets/images/background/bg2.jpg')"}}>
<div className="overlay-main bg-black opacity-07"></div>
<div className="container">

<div className="row conntact-home">
<div className="col-md-7 col-sm-7">
<div className="section-content p-tb70">
<div className="section-head text-left text-white">
<h2 className="text-uppercase">Request A Call Back</h2>
<div className="wt-separator-outer">
<div className="wt-separator bg-primary"></div>
</div>
</div>                              	
<div className="contact-home-left">
<form  className="cons-contact-form" method="post" action="#">
<div className="row">

<div className="col-md-6 col-sm-6">
    <div className="form-group">
    <input name="username" type="text" required className="form-control" placeholder="Name"/>
</div>
</div>

<div className="col-md-6 col-sm-6">
    <div className="form-group">
    <input name="email" type="text" className="form-control" required placeholder="Email"/>
</div>
</div>

<div className="col-md-6 col-sm-6">
    <div className="form-group">
    <input name="phone" type="text" className="form-control" required placeholder="Phone"/>
</div>
</div>

<div className="col-md-6 col-sm-6">
    <div className="form-group">
    <input name="subject" type="text" className="form-control" required placeholder="Subject"/>
</div>
</div>

<div className="col-md-12">
    <div className="form-group">
    <textarea name="message" className="form-control" rows="4" placeholder="Message"></textarea>
</div>
</div>

<div className="col-md-12">
    <button type="submit" className="site-button skew-icon-btn btn-block">
    <span className="font-18 inline-block text-uppercase p-lr15" >Submit Now</span> 
</button>
</div>

</div>
</form>
</div>                             
</div>
</div>                        
<div className="contact-home-right">
<div>
<img src="/assets/images/st-1.png" className="st-1" alt=""/>
</div>
</div>
</div>

</div>
</div> 
{/* <!-- CONTACT US OFFER SECTION END  -->  */}


{/* <!-- WHY CHOOSE US SECTION START  --> */}
<div className="section-full  p-t80 p-b80 bg-gray">
<div className="container">
<div className="section-head text-center">
<span className="wt-title-subline font-22 text-gray-dark m-b15">Buy and Sell Bitcoin</span>
<h2 className="text-uppercase">Why Choose Bitcoin</h2>
<div className="wt-separator-outer">
<div className="wt-separator bg-primary"></div>
</div>
<p>Now save your money by spending on exactly what you are looking to learn. We make it really simple for you to choose what you need and customise your learning process for an efficient and effective learning process! Save time and money while developing solid skills and knowledge</p>
</div>
<div className="section-content no-col-gap">
<div className="row">

<div className="col-md-4 col-sm-6 animate_line">
<div className="wt-icon-box-wraper  p-a30 center bg-white m-a5">
<div className="icon-lg text-primary m-b20" >
    <a href="#" className="icon-cell"><img src="/assets/images/icon/pick-29.png" alt=""/></a>
</div>
<div className="icon-content">
    <h4 className="wt-tilte text-uppercase font-weight-500">Instant Trading</h4>
    <p>Metastar is a Decentralized unique business opportunity where you can earn huge money and create new community.</p>
</div>
</div>
</div>

<div className="col-md-4 col-sm-6 animate_line">
<div className="wt-icon-box-wraper  p-a30 center bg-white m-a5">
<div className="icon-lg text-primary m-b20">
    <a href="#" className="icon-cell"><img src="/assets/images/icon/pick-28.png" alt=""/></a>
</div>
<div className="icon-content ">
    <h4 className="wt-tilte text-uppercase font-weight-500">Recurring Buying</h4>
    <p>A cryptocurrency or crypto currency is digital asset designed to work as a medium of exchange that uses strong cryptography to secure financial transactions</p>
</div>
</div>
</div>

<div className="col-md-4 col-sm-6 animate_line">
<div className="wt-icon-box-wraper  p-a30 center bg-white m-a5">
<div className="icon-lg text-primary m-b20">
    <a href="#" className="icon-cell"><img src="/assets/images/icon/pick-17.png" alt=""/></a>
</div>
<div className="icon-content">
    <h4 className="wt-tilte text-uppercase font-weight-500">Safe and Secure</h4>
    <p>Decentralized cryptocurrency is produced by the entire cryptocurrency system collectively, at a rate which is defined when the system is created and which is publicly known.</p>
</div>
</div>
</div>

<div className="col-md-4 col-sm-6 animate_line">
<div className="wt-icon-box-wraper  p-a30 center bg-white m-a5">
<div className="icon-lg text-primary m-b20">
    <a href="#" className="icon-cell"><img src="/assets/images/icon/pick-19.png" alt=""/></a>
</div>
<div className="icon-content">
    <h4 className="wt-tilte text-uppercase font-weight-500">Investment Planning</h4>
    <p>A safe also called a strongbox or coffer is a secure lockable box used for securing valuable objects against theft and/or damage from fire. A safe is usually a hollow cuboid or cylinder, with one face being removable or hinged to form a door.</p>
</div>
</div>
</div>

<div className="col-md-4 col-sm-6 animate_line">
<div className="wt-icon-box-wraper  p-a30 center bg-white m-a5">
<div className="icon-lg text-primary m-b20">
    <a href="#" className="icon-cell"><img src="/assets/images/icon/pick-12.png" alt=""/></a>
</div>
<div className="icon-content">
    <h4 className="wt-tilte text-uppercase font-weight-500">Covered By Insurance</h4>
    <p>The body and door may be cast from metal such as steel or formed out of plastic through blow molding. Bank teller safes typically are secured to the counter.</p>
</div>
</div>
</div>

<div className="col-md-4 col-sm-6 animate_line">
<div className="wt-icon-box-wraper  p-a30 center bg-white m-a5">
<div className="icon-lg text-primary m-b20">
    <a href="#" className="icon-cell"><img src="/assets/images/icon/pick-38.png" alt=""/></a>
</div>
<div className="icon-content">
    <h4 className="wt-tilte text-uppercase font-weight-500">Bitcoin Transaction</h4>
    <p>The body and door may be cast from metal such as steel or formed out of plastic through blow molding. Bank teller safes typically are secured to the counter.</p>
</div>
</div>
</div>
</div>
</div>
</div>
</div>
{/* <!-- WHY CHOOSE US SECTION END -->  */}


{/* <!-- SECTION TABLE START --> */}
<div className="section-full p-t80 p-b50">
<div className="container">
<div className="section-head text-center">
<h2 className="text-uppercase">Cryptocurrency Prices</h2>
<div className="wt-separator-outer"><div className="wt-separator bg-primary" ></div></div>
<p>Have your Metastar always with you, in your pocket! You pay by quickly scanning a QR-code. As a merchant, you receive payments reliably and instantly. Metastar Wallet is the first mobile</p>
</div>

<div id="no-more-tables">
<table className="col-md-12 table-bordered table-striped table-condensed cf wt-responsive-table text-black">
<thead className="cf">
<tr>
    <th>Cryptocurrency</th>
    <th>Price</th>
    <th className="numeric">24h % Change</th>
    <th className="numeric">24h Volume (coin)</th>
    <th className="numeric">Supply</th>
</tr>
</thead>
<tbody>
<tr>
    <td data-title="Code"><img src="/assets/images/coin-icon/bitcoin.png" className="p-r10" alt=""/> Bitcoin</td>
    <td data-title="Company">9,290.06 $</td>
    <td data-title="Price" className="numeric">14.44%</td>
    <td data-title="Change" className="numeric">218,087 BTC</td>
    <td data-title="Change %" className="numeric">923,629</td>
</tr>
<tr>
    <td data-title="Code"><img src="/assets/images/coin-icon/monero.png" className="p-r10" alt=""/> Monero</td>
    <td data-title="Company">407.13 $</td>
    <td data-title="Price" className="numeric">16.17%</td>
    <td data-title="Change" className="numeric">1,223,367 ETH</td>
    <td data-title="Change %" className="numeric">663,133</td>
</tr>
<tr>
    <td data-title="Code"><img src="/assets/images/coin-icon/DigitalCash.png" className="p-r10" alt=""/> DigitalCash</td>
    <td data-title="Company">7.47 $</td>
    <td data-title="Price" className="numeric">-1.09%</td>
    <td data-title="Change" className="numeric">3,352 XUC</td>
    <td data-title="Change %" className="numeric">427,132</td>
</tr>
<tr>
    <td data-title="Code"><img src="/assets/images/coin-icon/Veritaseum.png" className="p-r10" alt=""/> Veritaseum</td>
    <td data-title="Company">0.22 $</td>
    <td data-title="Price" className="numeric">20.80%</td>
    <td data-title="Change" className="numeric">211,418,299 XRP</td>
    <td data-title="Change %" className="numeric">719,280</td>
</tr>
<tr>
    <td data-title="Code"><img src="/assets/images/coin-icon/ripple.png" className="p-r10" alt=""/> Ripple</td>
    <td data-title="Company">84.22 $</td>
    <td data-title="Price" className="numeric">-14.56%</td>
    <td data-title="Change" className="numeric">36 VERI</td>
    <td data-title="Change %" className="numeric">218,887</td>
</tr>
<tr>
    <td data-title="Code"><img src="/assets/images/coin-icon/Exchange-_union.png" className="p-r10" alt=""/> Exchange Union</td>
    <td data-title="Company">709.31 $</td>
    <td data-title="Price" className="numeric">-1.18%</td>
    <td data-title="Change" className="numeric">125,361 DASH</td>
    <td data-title="Change %" className="numeric">427,132</td>
</tr>
<tr>
    <td data-title="Code"><img src="/assets/images/coin-icon/Ethereum.png" className="p-r10" alt=""/> Ethereum</td>
    <td data-title="Company">78.97 $</td>
    <td data-title="Price" className="numeric">20.55% </td>
    <td data-title="Change" className="numeric">2,281,288 LTC</td>
    <td data-title="Change %" className="numeric">719,280</td>
</tr>
<tr>
    <td data-title="Code"><img src="/assets/images/coin-icon/litMetastar.png" className="p-r10" alt=""/> LitMetastar</td>
    <td data-title="Company">156.32 $</td>
    <td data-title="Price" className="numeric">1.18%</td>
    <td data-title="Change" className="numeric">255,226 XMR</td>
    <td data-title="Change %" className="numeric">218,887</td>
</tr>
</tbody>
</table>
</div>                                   
</div>
</div>
{/* <!-- SECTION TABLE  END -->   */}


{/* <!-- ANY QUERY SECTION CONTENT START --> */}
<div className="section-full no-col-gap bg-repeat">
<div className="container-fluid">

<div className="row">
<div className="col-md-6 col-sm-6 bg-secondry">
<div className="section-content p-tb60 p-r30 clearfix">
<div className="wt-left-part any-query">
<img src="/assets/images/any-query.png" alt="" />
<div className="text-center">
    <h3 className="text-uppercase font-weight-500 text-white">Any Query?</h3>
    <p className="text-white">Once you’ve entered into our ecosystem, you can mange every thing. Anyone with a smartphone and an internet.</p>
    <h4 className="text-primary">+41 32 377 77 77</h4>
</div>    
</div>
</div>                               
</div>
<div className="col-md-6 col-sm-6 bg-primary">
<div className="section-content p-tb60 p-l30 clearfix">
<div className="wt-right-part any-query-contact">
<img src="/assets/images/any-query-contact.png" alt="" />
<div className="text-center">
    <h3 className="text-uppercase font-weight-500 text-white">Contact Us</h3>
    <p className="text-white">Once you’ve entered into our ecosystem, you can mange every thing. Anyone with a smartphone and an internet.</p>
    <h4 className="text-secondry">info@metastarworld.in</h4>
</div>                               
</div>                                
</div>
</div>
</div>

</div>
</div>
{/* <!-- ANY QUERY SECTION CONTENT  END --> */}


{/* <!-- LATEST BLOG SECTION START --> */}
<div className="section-full latest-blog bg-gray p-t80 p-b50">
<div className="container">

<div className="section-head text-center" >
<h2 className="text-uppercase">Latest blog post</h2>
<div className="wt-separator-outer">
<div className="wt-separator bg-primary"></div>
</div>
<p>Have your Ecoin always with you, in your pocket! You pay by quickly scanning a QR-code. As a merchant, you receive payments reliably and instantly. Ecoin Wallet is the first mobile</p>
</div>

<div className="section-content ">
<div className="row">
<div className="col-md-4 col-sm-4">
<div className="blog-post latest-blog-1 date-style-1">
<div className="wt-post-media wt-img-effect zoom-slow">
<a href="javascript:;"><img src="/assets/images/blog/blog-small/pic1.jpg" alt="" /></a>
</div>
<div className="wt-post-info p-a30 p-b20 bg-white">
<div className="wt-post-title ">
    <h3 className="post-title"><a href="javascript:;">Bitcoin and Altcoins.</a></h3>
</div>
<div className="wt-post-meta ">
    <ul>
        <li className="post-date"><i className="fa fa-calendar"></i><strong>10 Dec</strong> <span> 2017</span></li>
        <li className="post-author"><i className="fa fa-user"></i>By <a href="javascript:;">John</a> </li>
        <li className="post-comment"><i className="fa fa-comments"></i> <a href="javascript:;">0 comment</a> </li>
    </ul>
</div>

</div>
</div>
</div>

<div className="col-md-4 col-sm-4">
<div className="blog-post latest-blog-1 date-style-1">
<div className="wt-post-media wt-img-effect zoom-slow">
<a href="javascript:;"><img src="/assets/images/blog/blog-small/pic4.jpg" alt="" /></a>
</div>
<div className="wt-post-info p-a30 p-b20 bg-white">
<div className="wt-post-title ">
    <h3 className="post-title"><a href="javascript:;">Eight must read tips</a></h3>
</div>
<div className="wt-post-meta ">
    <ul>
        <li className="post-date"><i className="fa fa-calendar"></i><strong>10 Dec</strong> <span> 2017</span></li>
        <li className="post-author"><i className="fa fa-user"></i>By <a href="javascript:;">John</a> </li>
        <li className="post-comment"><i className="fa fa-comments"></i> <a href="javascript:;">0 comment</a> </li>
    </ul>
</div>

</div>
</div>
</div>

<div className="col-md-4 col-sm-4">
<div className="blog-post latest-blog-1 date-style-1">
<div className="wt-post-media wt-img-effect zoom-slow">
<a href="javascript:;"><img src="/assets/images/blog/blog-small/pic7.jpg" alt="" /></a>
</div>
<div className="wt-post-info p-a30 p-b20 bg-white">
<div className="wt-post-title ">
    <h3 className="post-title"><a href="javascript:;">Analysis for beginners.</a></h3>
</div>
<div className="wt-post-meta ">
    <ul>
        <li className="post-date"><i className="fa fa-calendar"></i><strong>10 Dec</strong> <span> 2017</span></li>
        <li className="post-author"><i className="fa fa-user"></i>By <a href="javascript:;">John</a> </li>
        <li className="post-comment"><i className="fa fa-comments"></i> <a href="javascript:;">0 comment</a> </li>
    </ul>
</div>

</div>
</div>
</div>
</div>
</div>
</div>
</div>
{/* <!-- LATEST BLOG SECTION END --> */}


{/* <!-- OUR CLIENT SLIDER START --> */}
<div className="section-full overlay-wraper bg-cover bg-repeat-x bg-primary" style={{backgroundImage:"url('/assets/images/background/bg-7.png')"}}>
<div className="container">

<div className="section-content">
<div className="owl-carousel home-logo-carousel">

<div className="item">
<div className="ow-client-logo">
<div className="client-logo wt-img-effect on-color">
    <a href="#"><img src="/assets/images/client-logo/w1.png" alt="" /></a>
</div>
</div>
</div>

<div className="item">
<div className="ow-client-logo">
<div className="client-logo wt-img-effect on-color">
    <a href="#"><img src="/assets/images/client-logo/w2.png" alt="" /></a>
</div>
</div>
</div>

<div className="item">
<div className="ow-client-logo">
<div className="client-logo wt-img-effect on-color">
    <a href="#"><img src="/assets/images/client-logo/w3.png" alt="" /></a>
</div>
</div>
</div>

<div className="item">
<div className="ow-client-logo">
<div className="client-logo wt-img-effect on-color">
    <a href="#"><img src="/assets/images/client-logo/w4.png" alt="" /></a>
</div>
</div>
</div>

<div className="item">
<div className="ow-client-logo">
<div className="client-logo wt-img-effect on-color">
    <a href="#"><img src="/assets/images/client-logo/w5.png" alt="" /></a>
</div>
</div>
</div>

<div className="item">
<div className="ow-client-logo">
<div className="client-logo wt-img-effect on-color">
    <a href="#"><img src="/assets/images/client-logo/w6.png" alt="" /></a>
</div>
</div>
</div>

<div className="item">
<div className="ow-client-logo">
<div className="client-logo wt-img-effect on-color">
    <a href="#"><img src="/assets/images/client-logo/w1.png" alt="" /></a>
</div>
</div>
</div>
 
<div className="item">
<div className="ow-client-logo">
<div className="client-logo wt-img-effect on-color">
    <a href="#"><img src="/assets/images/client-logo/w2.png" alt="" /></a>
</div>
</div>
</div>

<div className="item">
<div className="ow-client-logo">
<div className="client-logo wt-img-effect on-color">
    <a href="#"><img src="/assets/images/client-logo/w3.png" alt="" /></a>
</div>
</div>
</div>

<div className="item">
<div className="ow-client-logo">
<div className="client-logo wt-img-effect on-color">
    <a href="#"><img src="/assets/images/client-logo/w4.png" alt="" /></a>
</div>
</div>
</div>
 
<div className="item">
<div className="ow-client-logo">
<div className="client-logo wt-img-effect on-color">
    <a href="#"><img src="/assets/images/client-logo/w5.png" alt="" /></a>
</div>
</div>
</div>

<div className="item">
<div className="ow-client-logo">
<div className="client-logo wt-img-effect on-color">
    <a href="#"><img src="/assets/images/client-logo/w6.png" alt="" /></a>
</div>
</div>
</div>                        

</div>
</div>
</div>

</div>
{/* <!-- OUR CLIENT SLIDER END -->   */}


<Helmet>
<script src="/assets/js/jquery-1.12.4.min.js"></script>
    <script src="/assets/js/bootstrap.min.js"></script>
    <script src="/assets/js/bootstrap-select.min.js"></script>
    <script src="/assets/js/jquery.bootstrap-touchspin.min.js"></script>
    <script   src="/assets/js/magnific-popup.min.js"></script>
    <script   src="/assets/js/waypoints.min.js"></script>
    <script   src="/assets/js/counterup.min.js"></script>
    <script   src="/assets/js/waypoints-sticky.min.js"></script>
    <script  src="/assets/js/isotope.pkgd.min.js"></script>
    <script   src="/assets/js/owl.carousel.min.js"></script>
    <script   src="/assets/js/stellar.min.js"></script> 
    <script   src="/assets/js/scrolla.min.js"></script>
    <script   src="/assets/js/custom.js"></script>
    <script   src="/assets/js/shortcode.js"></script>
    <script   src="/assets/js/switcher.js"></script>
    <script  src="/assets/js/jquery.bgscroll.js"></script>
    <script  src="/assets/js/tickerNews.min.js"></script>
    
    <script  src="/assets/plugins/revolution/revolution/js/jquery.themepunch.tools.min.js"></script>
    <script  src="/assets/plugins/revolution/revolution/js/jquery.themepunch.revolution.min.js"></script>
    <script  src="/assets/plugins/revolution/revolution/js/extensions/revolution-plugin.js"></script>
    <script   src="/assets/js/rev-script-2.js"></script>
</Helmet>

        
    </div>
  )
}
